import styled from '@emotion/styled';
import { Link } from '$shared/components/Link';
import { Button } from '$shared/components/Button';
import { Text } from '$shared/components/Text';
import { Visibility } from '$shared/utils/jsx';
import { Usp } from '~/features/navigation/components/N11Meta/components/Usp';

export const StyledTopbarCampaignLink = styled(Link)({
    zIndex: 999,
});

export const StyledTopbarCampaign = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spaces[4],
    padding: `${theme.spaces['6px']} 0`,
    width: '100%',
    height: theme.spaces['26px'],
    zIndex: 999,
}));

export const StyledTopbarCampaignWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spaces[2],
    marginRight: theme.spaces[4],
}));

export const StyledTopbarCampaignButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    right: 0,
    zIndex: 1000,
    padding: `0 ${theme.spaces[2]}`,
}));

export const StyledTopbarCampaignTitle = styled(Text)(({ theme }) => ({
    fontSize: theme.fontSizes['xs'],
    fontWeight: 600,
}));

export const StyledTopbarCampaignText = styled(Text)(({ theme }) => ({
    fontSize: theme.fontSizes['xs'],
    fontWeight: 400,
}));

export const StyledTopbarCampaignStaticMobile = styled(Visibility.HiddenFrom)({
    width: '100%',
});

export const StyledTopbarCampaignStaticDesktop = styled(Visibility.VisibleFrom)({
    margin: '0 auto',
});

export const StyledTopbarCampaignStaticUsp = styled(Usp)();
