import React, { ComponentType } from 'react';
import dynamic from 'next/dynamic';
import { LazyMotion } from 'framer-motion';
import {
    usePage,
    DynamicLayout,
    OpenGraph,
    DynamicFavicon,
    MetaFields,
    Scripts,
    DynamicTracking,
} from '~/templates/pages';
import { DynamicPageProps } from '~/pages/[[...slug]]';
import { Skeleton, SkipToContent, TopbarCampaign } from '$shared/components';
import { useFrame } from '$shared/utils';
import { useGlobalState } from '$shared/hooks';
import { useTopbarCampaignState } from '$shared/components/TopbarCampaign/TopbarCampaignState';
import { MiniBasket } from '~/features/basket';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const pageType: Record<string, ComponentType<any>> = {
    p60ModulePage: dynamic(() => import('../P60ModulePage')),
    p70ArticleOverviewPage: dynamic(() => import('../P70ArticleOverviewPage')),
    p80ArticlePage: dynamic(() => import('../P80ArticlePage')),
    p140NotFoundPage: dynamic(() => import('../P140NotFoundPage')),
    p160ClubPage: dynamic(() => import('../P160ClubPage')),
    p170StoreOverviewPage: dynamic(() => import('../P170StoreOverviewPage')),
    p171StoreOverviewPage: dynamic(() => import('../P171StoreOverviewPage')),
    p180StorePage: dynamic(() => import('../P180StorePage')),
    p190LoginPage: dynamic(() => import('../P190LoginPage')),
    p200ActivationPage: dynamic(() => import('../P200ActivationPage')),
    p220ResetPasswordPage: dynamic(() => import('../P220ResetPasswordPage')),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customPageType: Record<string, ComponentType<any>> = {
    ['/zendesk/kvittering']: dynamic(() => import('../ZendeskReceiptPage')),
};

const loadFramerMotionFeatures = () => import('./framerFeatures').then((res) => res.default);

export const DynamicPage = ({ pageBaseUrl, resolvedUrl }: DynamicPageProps) => {
    const { type = '' } = usePage();
    const { data: frame } = useFrame();
    const { pageLoaded } = useGlobalState();
    const { hideTopBarCampaign } = useTopbarCampaignState();
    const path = resolvedUrl?.split('?')[0];
    const customPageComponent = customPageType[path ?? ''];
    const PageComponent = customPageComponent ?? pageType[type] ?? pageType.p60ModulePage;
    const shouldShowSkeleton = ['p90basketPage'].includes(type) && !pageLoaded;
    const topBar = frame?.settings?.currentTopbarCampaign?.topbarCampaign;

    return (
        <LazyMotion features={loadFramerMotionFeatures} strict>
            <MetaFields pageBaseUrl={pageBaseUrl} />
            <OpenGraph />
            <DynamicFavicon />
            {!hideTopBarCampaign && topBar && !topBar?.disable && (
                <TopbarCampaign topbarCampaign={topBar} />
            )}
            <MiniBasket />
            <DynamicLayout>
                <SkipToContent />
                <PageComponent />
                {shouldShowSkeleton && <Skeleton style={{ width: '1fr', height: '100vh' }} />}
            </DynamicLayout>
            <Scripts />
            <DynamicTracking />
        </LazyMotion>
    );
};
