import styled from '@emotion/styled';

export const StyledSkipToContent = styled.a(({ theme }) => ({
    position: 'absolute',
    backgroundColor: theme.colors.light,
    left: '-100000px',
    top: '-100000px',
    zIndex: 1000,
    padding: `${theme.spaces[2]} ${theme.spaces[3]}`,
    border: `2px solid ${theme.colors.dark}`,
    color: theme.colors.dark,
    borderRadius: theme.borders.radius,
    outline: 'none',
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.medium,
    fontSize: theme.fontSizes.sm,
    ':focus': {
        borderColor: theme.colors.blue50,
        left: theme.spaces[2],
        top: theme.spaces[2],
    },
}));
