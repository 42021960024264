import React, { memo, useCallback } from 'react';
import { Overlay, Button, OverlayProps, RouterLink, SizeCarousel } from '~/shared/components';
import { useBasket } from '../../hooks';
import {
    StyledMiniBasketItems,
    StyledMiniBasketContent,
    StyledMiniBasketActions,
    StyledMiniBasketHeadline,
    StyledMiniBasketSummarize,
    StyledMiniCarousel,
    StyledFreeFreightContainer,
} from './styled';
import Check from '$icons/check.svg';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import { MiniBasketItem } from './components/MiniBasketItem';
import { FreeShippingProgress } from '../FreeShippingProgress';
import { weakKey } from '~/shared/utils/jsx';
import { Spacer } from '~/theme';
import { Summary } from '~/features/checkout';
import { AnimatePresence } from 'framer-motion';
export type MiniBasketProps = Partial<OverlayProps>;

export const MiniBasket = memo((overlayProps: MiniBasketProps) => {
    const { data: basket, isMiniBasketOpen, toggleMiniBasket, totalQuantity } = useBasket();
    const { data: frameData } = useFrame();
    const { translate } = useTranslation();
    const staticLinks = frameData?.staticLinks;

    const closeMiniBasket = useCallback(() => toggleMiniBasket(false), [toggleMiniBasket]);

    return (
        <Overlay
            open={isMiniBasketOpen}
            variant="slide"
            backdrop={true}
            onDismiss={closeMiniBasket}
            headline={
                <StyledMiniBasketHeadline>
                    <Check />
                    {translate('minibasket.headline')}
                </StyledMiniBasketHeadline>
            }
            {...overlayProps}
        >
            <StyledMiniBasketContent>
                <StyledMiniBasketItems>
                    <AnimatePresence initial={false}>
                        {[...(basket?.lines ?? [])]?.reverse()?.map((line) => (
                            <MiniBasketItem {...line} key={weakKey(line)} />
                        ))}
                    </AnimatePresence>
                </StyledMiniBasketItems>
                <StyledMiniBasketSummarize>
                    <StyledFreeFreightContainer>
                        <FreeShippingProgress variant="minimal" />
                    </StyledFreeFreightContainer>
                    {basket && <Summary sum={basket} variant="minimal" />}
                </StyledMiniBasketSummarize>
                <StyledMiniCarousel>
                    {frameData?.settings?.features?.carouselContextId && (
                        <SizeCarousel
                            translations={{
                                headline: translate('minibasket.carouselHeadline'),
                                cardButtonText: translate('minibasket.carouselCardButtonText'),
                            }}
                            contextId={frameData.settings.features.carouselContextId}
                            trackingEvent="trackMiniBasketAddToBasket"
                        />
                    )}
                </StyledMiniCarousel>
                <Spacer space="3" />
                <StyledMiniBasketActions>
                    <Button variant="secondary" onClick={closeMiniBasket} size="md">
                        {translate('minibasket.continueButton')}
                    </Button>
                    {staticLinks?.basketPage?.url && (
                        <RouterLink href={staticLinks.basketPage.url}>
                            <Button as="a" size="md">
                                {translate('minibasket.goTobasketButton', {
                                    quantity: totalQuantity || '0',
                                })}
                            </Button>
                        </RouterLink>
                    )}
                </StyledMiniBasketActions>
            </StyledMiniBasketContent>
        </Overlay>
    );
});
