/**
 * Prettyfies page and module names to title case with spaces
 * Input: myPage
 * Output: My Page
 * @param source
 * @returns
 */
const formatSource = (source: string) => {
    return source
        .split(/(?=[A-Z][a-z]+)/)
        .map((word) => `${word[0]?.toUpperCase()}${word.slice(1)}`)
        .join(' ');
};

/**
 * Formats page and module aliases to pagename with ID
 * Input: M150MyPage
 * Output: MyPage
 * @param alias
 */
const mapSource = (alias: string) => {
    const nameArr = alias.split(/[\d]/);
    const name = nameArr[nameArr.length - 1] || '';

    return name ? formatSource(name) : '';
};

export { formatSource, mapSource };
