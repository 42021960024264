import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { scrollbar } from '~/shared/utils/styled';
import { breakpoints } from '~/theme';

export const StyledMiniBasketHeadline = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spaces[3],
    svg: {
        width: theme.spaces[4],
        height: theme.spaces[4],
    },
}));

export const StyledMiniBasketContent = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spaces[5]} ${theme.spaces[3]}`,
    flex: 1,
    overflow: 'hidden',
}));

export const StyledMiniBasketItems = styled.div(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[2],
    overflowY: 'auto',
    borderBottom: `1px solid ${theme.traits.line.color}`,
    paddingBottom: theme.spaces[4],
    overflowX: 'hidden',
    ...scrollbar,
}));

export const StyledMiniBasketItem = styled(m.li)(({ theme }) => ({
    display: 'grid',
    gap: theme.spaces[3],
    gridAutoFlow: 'column',
    gridTemplateColumns: '0.2fr 1fr',
    alignItems: 'center',
}));

export const StyledMiniBasketSummarize = styled.div(({ theme }) => ({
    marginTop: 'auto',
    marginBottom: theme.spaces[3],
    paddingBottom: theme.spaces[2],
    borderBottom: `1px solid ${theme.traits.line.color}`,
}));

export const StyledMiniBasketActions = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spaces[3],
}));

export const StyledMiniCarousel = styled.div();

export const StyledFreeFreightContainer = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spaces[2]} 0`,
    [breakpoints.md]: {
        padding: `${theme.spaces[4]} 0`,
    },
}));
