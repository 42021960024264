import React from 'react';
import {
    StyledTopbarCampaignLink,
    StyledTopbarCampaign,
    StyledTopbarCampaignWrapper,
    StyledTopbarCampaignButton,
    StyledTopbarCampaignTitle,
    StyledTopbarCampaignText,
    StyledTopbarCampaignStaticMobile,
    StyledTopbarCampaignStaticDesktop,
    StyledTopbarCampaignStaticUsp,
} from './styled';
import { TopbarCampaign as TopbarCampaignModel } from '~/lib/data-contract';
import { getUnixTime } from 'date-fns';
import Marquee from 'react-fast-marquee';
import CloseIcon from '$icons/close.svg';
import { useTopbarCampaignState } from '$shared/components';

type TopbarCampaignProps = {
    topbarCampaign: TopbarCampaignModel;
};

export const TopbarCampaign = ({ topbarCampaign }: TopbarCampaignProps) => {
    const { expiryDatetime, ...props } = topbarCampaign;

    if (expiryDatetime) {
        const expiryDatetimeTimestamp = getUnixTime(new Date(expiryDatetime));
        const currentDatetimeTimestamp = getUnixTime(new Date());
        if (expiryDatetimeTimestamp < currentDatetimeTimestamp) {
            return null;
        }
    }

    if (!props.title && !props.text) {
        return null;
    }

    return <TopbarCampaignContent {...props} />;
};

const TopbarCampaignContent = ({
    backgroundColor,
    textColor,
    animation,
    link,
    ...rest
}: TopbarCampaignModel) => {
    const { setHideTopBarCampaign } = useTopbarCampaignState();

    return (
        <StyledTopbarCampaign style={{ backgroundColor, color: textColor }}>
            <>
                {animation ? (
                    <Marquee autoFill speed={30} pauseOnHover>
                        {link?.url ? (
                            <StyledTopbarCampaignLink href={link.url} showHoverIndicator={false}>
                                <TopbarCampaignTitleAndText
                                    {...{ backgroundColor, textColor, ...rest }}
                                />
                            </StyledTopbarCampaignLink>
                        ) : (
                            <TopbarCampaignTitleAndText
                                {...{ backgroundColor, textColor, ...rest }}
                            />
                        )}
                    </Marquee>
                ) : (
                    <>
                        <StyledTopbarCampaignStaticMobile md>
                            {link?.url ? (
                                <StyledTopbarCampaignLink
                                    href={link.url}
                                    showHoverIndicator={false}
                                >
                                    <StyledTopbarCampaignStaticUsp
                                        centerAligned
                                        uspItems={[{ text: rest.title }, { text: rest.text }]}
                                    />
                                </StyledTopbarCampaignLink>
                            ) : (
                                <StyledTopbarCampaignStaticUsp
                                    centerAligned
                                    uspItems={[{ text: rest.title }, { text: rest.text }]}
                                />
                            )}
                        </StyledTopbarCampaignStaticMobile>
                        <StyledTopbarCampaignStaticDesktop md>
                            {link?.url ? (
                                <StyledTopbarCampaignLink
                                    href={link.url}
                                    showHoverIndicator={false}
                                >
                                    <TopbarCampaignTitleAndText
                                        {...{ backgroundColor, textColor, ...rest }}
                                    />
                                </StyledTopbarCampaignLink>
                            ) : (
                                <TopbarCampaignTitleAndText
                                    {...{ backgroundColor, textColor, ...rest }}
                                />
                            )}
                        </StyledTopbarCampaignStaticDesktop>
                    </>
                )}
                <StyledTopbarCampaignButton
                    onClick={() => setHideTopBarCampaign(true)}
                    children={<CloseIcon />}
                    shape="icon"
                    size="sm"
                    variant="tertiary"
                    showHoverIndicator={false}
                    type="button"
                    style={{ color: textColor, backgroundColor: backgroundColor }}
                />
            </>
        </StyledTopbarCampaign>
    );
};

const TopbarCampaignTitleAndText = ({ textColor, title, text }: TopbarCampaignModel) => {
    return (
        <StyledTopbarCampaignWrapper>
            {title && (
                <StyledTopbarCampaignTitle as="p" variant="bodySm" style={{ color: textColor }}>
                    {title}
                </StyledTopbarCampaignTitle>
            )}
            {text && (
                <StyledTopbarCampaignText as="p" variant="bodySm" style={{ color: textColor }}>
                    {text}
                </StyledTopbarCampaignText>
            )}
        </StyledTopbarCampaignWrapper>
    );
};
