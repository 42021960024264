import React from 'react';

import { GetServerSidePropsContext } from 'next';
import { dehydrate, HydrateProps, QueryClient } from 'react-query';
import { Pages, TrackingSetup, VWOSetup } from '~/lib/data-contract';
import {
    DynamicPage,
    errorHandler,
    Franchise,
    getCustomHeaders,
    getForwardRequestHeaders,
} from '~/templates/pages';
import { dataFetcher, staticDataResolver } from '~/templates/pages/utils';
import { getIsInitialLoad } from '~/templates/pages/utils/isInitialLoad';

export type DynamicPageProps = {
    page: Pages;
    franchise?: Franchise;
    dehydratedState?: HydrateProps['state'];
    settings: {
        referer: string;
        trackingSetup?: TrackingSetup;
        VWOSetup?: VWOSetup;
    };
    pageBaseUrl?: string;
    resolvedUrl?: string;
};

export const ZendeskReceiptUrl = '/zendesk/kvittering';

const Slug = (props: DynamicPageProps) => {
    return <DynamicPage {...props} />;
};

const headersFromCMS = ['cache-control'];
// Make sure we extract and forward the right headers
const headersToForward = ['accept-language', 'x-vercel-ip-country', 'x-forwarded-for'];

/**
 * Fetch page, frame and any page specific requests, return as props
 * CacheControl is set based on the response header from the page request
 */
export const getServerSideProps = async (context: GetServerSidePropsContext) => {
    const { req, res, resolvedUrl, locale = '' } = context;
    const { host = '' } = req.headers;
    const isInitialLoad = getIsInitialLoad(context);

    const queryClient = new QueryClient();

    const forwardHeaders = getForwardRequestHeaders(req.headers, headersToForward);
    const customHeaders = await getCustomHeaders(req);
    const headers = { ...forwardHeaders, ...customHeaders };

    let data;
    try {
        data = await dataFetcher({
            headers,
            host,
            locale,
            queryClient,
            resolvedUrl,
            isInitialLoad,
        });
    } catch (error) {
        return errorHandler(error, resolvedUrl);
    }

    const { pageResponse, frame, franchise, originalStatus } = data || {};

    const { settings } = staticDataResolver({ franchise, frame, headers, isInitialLoad });

    // Map potential headers from the page request to the client
    if (pageResponse?.headers) {
        headersFromCMS.forEach((headerKey) => {
            const header = pageResponse.headers.get(headerKey);
            header && res.setHeader(headerKey, header);
        });
    }

    // Check for redirects
    if (pageResponse?.data.type === 'redirect') {
        const { destination, permanent } = pageResponse.data;
        return { redirect: { destination, permanent } };
    }

    // Return original status code
    if (
        originalStatus !== 200 &&
        typeof originalStatus === 'number' &&
        !resolvedUrl.includes(ZendeskReceiptUrl)
    ) {
        res.statusCode = originalStatus;
    }

    // Remove cache-control header for 401 responses
    if (originalStatus === 401) {
        res.removeHeader('cache-control');
    }

    const dehydratedState = dehydrate(queryClient);

    // Clear the cache to avoid high memory consumption on server
    queryClient.clear();

    return {
        props: {
            dehydratedState,
            franchise,
            settings,
            pageBaseUrl: host?.includes('local') ? `http://${host}` : `https://${host}`,
            resolvedUrl,
        },
    };
};

export default Slug;
