import React, {
    memo,
    useEffect,
    useRef,
    useState,
    MouseEvent,
    useCallback,
    useImperativeHandle,
    forwardRef,
} from 'react';
import { useEffectOnce, useIntersection, useInterval } from 'react-use';
import { StyledItemWrapper, StyledScrollArea } from './styled';
import { elasticScroll, getAverageVelocity } from './utils';
import { defaultConfig, AdjustableConfig } from './config';
import { useReducedMotion } from 'framer-motion';
import { breaks } from '~/theme';
import { StyledScrollbar, StyledScrollBarContainer, StyledThumb } from '../ScrollArea/styled';

export type CarouselHandle = {
    goLeft: () => void;
    goRight: () => void;
};
export type Bounds = {
    startReached: boolean;
    endReached: boolean;
};
export type Props = {
    children?: JSX.Element[] | JSX.Element;
    /**
     * Automatic scrolling through the carousel, when it is in view.
     */
    autoPlay?: boolean;

    /**
     * @property {number}   momentumMinSpeed  The minimum speed in momentum scroll for it to stop completely.
     * @property {number}   momentumDeclineRate  How fast momentum will decline
     * @property {number}   boundaryResistance  The extra resistance when dragging out of bounds
     * @property {number}   boundarySnapBackDuration  Transition time between scrolling out of bounds and snapping back to normal
     * @property {number}   arrowScrollLength  Describes how far there will be scrolled on left/right arrow click
     * @property {boolean}   scrollbar  Enable or disable scrollbar
     */
    config?: AdjustableConfig;
    /**
     * Optional timestamp property for stopping internal functions, used when it's not possible
     * to wait until functionality reach it's end execution
     *
     * Pass external event timestamp
     */
    timestamp?: number;
    /**
     * function to be run every time the scroll updates. Usefull to update buttons
     * that should became disabled on scroll end/start
     */
    onUpdateScroll?: (scrollRef: React.RefObject<HTMLDivElement>) => void;
    /**
     * true if the amount of scroll needs to be calculated instead of being full width.
     */
    customScroll?: boolean;
    hasGutter?: boolean;
    onBoundsChanged?: (args: Bounds) => void;
};

let stopMomentum = false;
let stateTimeout: NodeJS.Timeout | null = null;
// Store values in between start of drag / drag / drag end
let initialScrollPosition: number;
let initialX: number;
let positions: Position[] = [];

type Position = {
    timeStamp: number;
    xPosition: number;
};

export const cardsWidthOptions = {
    xs: 2.75,
    sm: 3.75,
    md: 4.75,
};

/**
 * Wraps and array of children in a draggable carousel.
 * @param children An array of JSX elements.
 * @param autoPlay If set to true, will enable the carousel to scroll by itself when in the viewport.
 * @param stopMoving By changing this value, the carousel will stop its current momentum, og stop autoplay.
 * This can be used, when you want to scroll the carousel from an outer component.
 */

export const CarouselDrag = memo(
    forwardRef<CarouselHandle, Props>(
        (
            {
                children = [],
                autoPlay = false,
                config,
                timestamp = 0,
                customScroll,
                hasGutter = false,
                onBoundsChanged,
            }: Props,
            ref
        ) => {
            const [isDragging, setIsDragging] = useState(false);
            const [autoPlayActive, setAutoPlayActive] = useState(autoPlay);

            const {
                momentumMinSpeed,
                momentumDeclineRate,
                momentumDataSize,

                boundaryResistance,
                boundarySnapBackDuration,

                autoPlayScrollSpeed,
                autoPlayScrollLenght,

                arrowScrollLength,
            } = { ...defaultConfig, ...config };

            // These default values, prevents null checks in the functions.
            // Used to increase readability and performance.
            const slider = useRef<HTMLDivElement>(document.createElement('div'));
            const itemWrapper = useRef<HTMLDivElement>(document.createElement('div'));

            const shouldReduceMotion = useReducedMotion();

            const intersection = useIntersection(itemWrapper, {});

            const handleDragStart = (event: MouseEvent): void => {
                event.preventDefault();
                setIsDragging(true);
                setAutoPlayActive(false);

                if (slider.current !== null && itemWrapper.current !== null) {
                    initialScrollPosition = itemWrapper.current.scrollLeft;
                    initialX = event.pageX - itemWrapper.current.offsetLeft;

                    itemWrapper.current.style.transitionDuration = '0ms';
                    positions = [];
                }

                stopMomentum = true;
            };

            const handleDrag = (event: MouseEvent): void => {
                event.preventDefault();

                const desiredScrollLeft =
                    initialScrollPosition + initialX - event.pageX - itemWrapper.current.offsetLeft;

                const outOfBounds = elasticScroll(
                    desiredScrollLeft,
                    itemWrapper,
                    itemWrapper,
                    boundaryResistance
                );

                if (outOfBounds) {
                    return;
                }

                itemWrapper.current.scrollLeft = desiredScrollLeft;

                // Save data, to use later for momentum scroll
                positions.push({
                    timeStamp: event.timeStamp,
                    xPosition: event.pageX,
                });
                if (positions.length > momentumDataSize) {
                    positions.shift();
                }
            };

            const handleDragStop = () => {
                setIsDragging(false);
                itemWrapper.current.style.transitionDuration = `${boundarySnapBackDuration}ms`;
                itemWrapper.current.style.transform = '';

                stopMomentum = false;

                if (positions.length >= momentumDataSize) {
                    requestAnimationFrame(() => momentumScroll(getAverageVelocity(positions)));
                }
            };

            // Start scrolling
            const momentumScroll = (velocity: number) => {
                if (Math.abs(velocity) < momentumMinSpeed || stopMomentum) {
                    return;
                }

                itemWrapper.current.scrollLeft -= velocity;
                requestAnimationFrame(() => momentumScroll(velocity / momentumDeclineRate));
            };

            const onclickHandler = (event: MouseEvent<HTMLElement>) => {
                if (positions.length > 0) {
                    event.preventDefault();
                }
            };

            const arrowClick = useCallback(
                (direction: number): void => {
                    stopMomentum = true;
                    setAutoPlayActive(false);

                    let scrollWidth =
                        itemWrapper.current.getBoundingClientRect().width * arrowScrollLength;
                    if (customScroll) {
                        const clientWidth = window.innerWidth || 0;
                        let cardsWidth = cardsWidthOptions.xs;
                        let cardsToScroll = 2;
                        if (clientWidth >= breaks.md) {
                            cardsWidth = cardsWidthOptions.md;
                            cardsToScroll = 3;
                        } else if (clientWidth >= breaks.sm) {
                            cardsWidth = cardsWidthOptions.sm;
                        }

                        scrollWidth =
                            (itemWrapper.current.getBoundingClientRect().width / cardsWidth) *
                                cardsToScroll +
                            8; //8px to compensate first child not having left padding
                    }

                    itemWrapper.current.scrollTo({
                        left: itemWrapper.current.scrollLeft + scrollWidth * direction,
                        behavior: shouldReduceMotion ? 'auto' : 'smooth',
                    });
                },
                [arrowScrollLength, customScroll, shouldReduceMotion]
            );

            const detectBounds = () => {
                const startReached = itemWrapper.current.scrollLeft <= 0;
                const endReached =
                    itemWrapper.current.scrollLeft + itemWrapper.current.clientWidth >=
                    itemWrapper.current.scrollWidth;

                onBoundsChanged?.({ startReached, endReached });
            };

            const scrollHandler = () => {
                if (stateTimeout !== null) {
                    clearTimeout(stateTimeout);
                }

                stateTimeout = setTimeout(() => {
                    detectBounds();
                }, 200);
            };

            useImperativeHandle(ref, () => ({
                goLeft: () => {
                    arrowClick(1);
                },
                goRight: () => {
                    arrowClick(-1);
                },
            }));

            useInterval(
                () => {
                    requestAnimationFrame(() => {
                        itemWrapper.current.scrollLeft += autoPlayScrollLenght;
                    });
                },
                autoPlayActive && intersection?.isIntersecting && !shouldReduceMotion
                    ? autoPlayScrollSpeed
                    : null
            );

            useEffect(() => {
                if (timestamp > 0) {
                    stopMomentum = true;
                    setAutoPlayActive(false);
                }
            }, [timestamp]);

            useEffectOnce(() => {
                detectBounds();
            });

            return (
                <StyledScrollArea
                    type="auto"
                    ref={slider}
                    onClickCapture={onclickHandler}
                    onMouseDown={handleDragStart}
                    onMouseLeave={isDragging ? handleDragStop : undefined}
                    onMouseUp={isDragging ? handleDragStop : undefined}
                    onMouseMove={isDragging ? (event) => handleDrag(event) : undefined}
                    onFocus={autoPlayActive ? () => setAutoPlayActive(false) : undefined}
                    onTouchStart={autoPlayActive ? () => setAutoPlayActive(false) : undefined}
                >
                    <StyledItemWrapper
                        hasGutter={hasGutter}
                        onScroll={scrollHandler}
                        ref={itemWrapper}
                    >
                        {children}
                    </StyledItemWrapper>
                    <StyledScrollBarContainer hasGutter={hasGutter} showScrollbar={true}>
                        <StyledScrollbar orientation="horizontal">
                            <StyledThumb />
                        </StyledScrollbar>
                    </StyledScrollBarContainer>
                </StyledScrollArea>
            );
        }
    )
);
