import React, { useMemo } from 'react';
import { FormattedAlgoliaProductType } from '~/features/productsList/types';
import { mapFromAlgolia } from '~/features/productsList/utils';
import { Text, PriceGroup, Button, Link } from '~/shared/components';
import { AlgoliaHit } from '~/templates/blocks/components/Carousel/models';
import { StyledActionSection, StyledInformationSection, StyledProductCardSize } from './styled';
import { ProductImage } from '../ProductImage';
import { useFranchise } from '~/shared/utils';

type OnClickArgs = { productId: string; productColorId: string };
type Props = {
    hit: AlgoliaHit;
    isLoading: boolean;
    onClick: (args: OnClickArgs) => void;
    buttonText?: string;
    imagePriority?: 'Product' | 'Model';
};

export const ProductCardSize = (props: Props) => {
    const franchise = useFranchise();
    const {
        images,
        name,
        priceDetails,
        productId,
        objectId,
        productUrl,
    }: FormattedAlgoliaProductType = useMemo(
        () => mapFromAlgolia(props.hit, franchise, parseInt(props.hit.__position)),
        [props.hit, franchise]
    );
    const image = props.imagePriority
        ? images.find(({ label }) => label?.startsWith(props.imagePriority ?? '')) ?? images?.[0]
        : images?.[0];

    return (
        <StyledProductCardSize>
            <StyledInformationSection>
                {image && (
                    <Link href={productUrl} type="router">
                        <ProductImage
                            image={image}
                            alt={name ?? ''}
                            height={105}
                            width={86}
                            style={{ objectFit: 'contain', width: 'auto' }}
                        />
                    </Link>
                )}
            </StyledInformationSection>

            <StyledActionSection>
                {name && <Text variant="caption">{name}</Text>}
                {priceDetails && <PriceGroup variant="overline" {...priceDetails} />}
                {props.buttonText && (
                    <Button
                        size="sm"
                        variant="secondary"
                        onClick={() => props.onClick({ productId, productColorId: objectId })}
                        disabled={props.isLoading}
                    >
                        {props.buttonText}
                    </Button>
                )}
            </StyledActionSection>
        </StyledProductCardSize>
    );
};
