import styled from '@emotion/styled';

export const StyledProductCardSize = styled.div(({ theme }) => ({
    display: 'flex',
    width: 220,
    height: '100%',
    gap: theme.spaces[2],
}));

export const StyledInformationSection = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
});

export const StyledActionSection = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[1],
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    '& > :first-child': {
        margin: `${theme.spaces[3]} 0 auto`,
    },
}));
