import { useBasket } from '~/features/basket';
import { PageViewData } from '~/shared/utils/gtm/lib/events/trackPageViewEvent';
import { gtmProductMapper, useFranchise, useGtm } from '~/shared/utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FRANCHISE } from '~/constants/franchise';
import { useIsInitialRender } from '~/shared/hooks';
import { useProduct } from '~/shared/utils/product';
import { runtimeConfig } from '~/shared/utils/public-variables';
import { useSource } from '~/shared/utils/gtm';
import { useRouter } from 'next/router';

const { ENVIRONMENT_NAME } = runtimeConfig();

export const usePageViewTracker = () => {
    const pagePathRef = useRef('');
    const { trackPage, trackProductDetailImpression } = useGtm();
    const { activeMarket, key } = useFranchise();
    const { data: basket, status: basketStatus } = useBasket();
    const { initialLocation } = useIsInitialRender();
    const { data: product } = useProduct();
    const { asPath } = useRouter();
    const { source, previousSource = '' } = useSource();

    const currentFranchiseName = useMemo(() => {
        const franchises = Object.keys(FRANCHISE);
        return franchises.find((name) => FRANCHISE[name] === key) || franchises[0];
    }, [key]);

    const pageViewConfig: PageViewData = useMemo(
        () => ({
            countrySite: activeMarket?.currency || '',
            bu: currentFranchiseName,
            pageType: source,
            originalLocation: initialLocation || '',
            environment: ENVIRONMENT_NAME,
            anonymousID: basket?.anonoymousId,
        }),
        [
            activeMarket?.currency,
            basket?.anonoymousId,
            currentFranchiseName,
            initialLocation,
            source,
        ]
    );

    const trackPageViewWithConfig = useCallback(() => {
        trackPage(pageViewConfig);

        if (product) {
            trackProductDetailImpression(
                gtmProductMapper([product]),
                previousSource,
                activeMarket?.currency
            );
        }
    }, [
        activeMarket?.currency,
        pageViewConfig,
        product,
        trackPage,
        trackProductDetailImpression,
        previousSource,
    ]);

    useEffect(() => {
        const shouldTrackPage =
            basketStatus !== 'loading' && initialLocation && pagePathRef.current !== asPath;

        if (shouldTrackPage) {
            pagePathRef.current = asPath;
            trackPageViewWithConfig();
        }
    }, [asPath, basketStatus, initialLocation, trackPageViewWithConfig]);
};
