import React, { memo, useState } from 'react';
import { UspSimple } from '~/lib/data-contract';
import {
    StyledAccesibilityUspDisplay,
    StyledUspContainer,
    StyledUspItem,
    StyledWrapper,
} from './styled';
import { animationOptions } from './animations';
import { Usp as UspItem } from '~/shared/components';
import { useInterval, useMedia } from 'react-use';
import { Variants } from 'framer-motion';
import { weakKey } from '~/shared/utils/jsx';
import { useTheme } from '@emotion/react';
import { queries, useThemeShade } from '~/theme';

export type Props = {
    uspItems?: UspSimple[];
    animationDelay?: number;
    animationType?: Variants;
    centerAligned?: boolean;
    /**
     * Adjusts the colors of the USP's image and text to the background color.
     * which is typically determined by the theme.
     */
    adjustColorsToBackground?: boolean;
};

/**
 * Takes a list of USP's and
 * loops through them. Animation
 * properties can be controlled.
 */
export const Usp = memo(
    ({
        uspItems = [],
        animationDelay = 5000,
        animationType = animationOptions.fade,
        centerAligned,
        adjustColorsToBackground,
    }: Props) => {
        const [shownItem, setShownItem] = useState(0);
        const [paused, setPaused] = useState(false);
        const [isTabFocus, setIsTabFocus] = useState(false);
        const { traits } = useTheme();
        const { textShade } = useThemeShade({ backgroundColor: traits.background.n11meta });
        const isMobile = !useMedia(queries.md, false);

        const shade = adjustColorsToBackground ? textShade : undefined;

        useInterval(
            () => {
                setShownItem((prev) => ++prev % uspItems.length);
            },
            paused ? null : animationDelay
        );

        return isMobile ? (
            <StyledUspContainer
                onMouseOver={() => setPaused(true)}
                onMouseOut={() => setPaused(false)}
                onKeyDown={(event) => {
                    if (event.code === 'Tab') {
                        setIsTabFocus(true);
                    }
                }}
                onBlur={(event) => {
                    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
                        setIsTabFocus(false);
                    }
                }}
                centerAligned={centerAligned}
            >
                {isTabFocus ? (
                    <StyledWrapper>
                        <StyledAccesibilityUspDisplay>
                            {uspItems.map((usp) => (
                                <li key={weakKey(usp)}>
                                    <UspItem uspItem={usp} shade={shade} variant="bodySm" />
                                </li>
                            ))}
                        </StyledAccesibilityUspDisplay>
                    </StyledWrapper>
                ) : (
                    uspItems.map((usp, index) => (
                        <StyledUspItem
                            key={weakKey(usp)}
                            variants={animationType}
                            animate={shownItem === index ? 'animate' : 'exit'}
                            style={{ zIndex: shownItem === index ? 1 : 0 }}
                            initial="initial"
                        >
                            <UspItem uspItem={usp} shade={shade} variant="bodySm" />
                        </StyledUspItem>
                    ))
                )}
            </StyledUspContainer>
        ) : (
            <StyledUspContainer style={{ justifyContent: 'space-between' }}>
                {isTabFocus ? (
                    <StyledWrapper>
                        <StyledAccesibilityUspDisplay>
                            {uspItems.map((usp) => (
                                <li key={weakKey(usp)}>
                                    <UspItem uspItem={usp} shade={shade} variant="bodySm" />
                                </li>
                            ))}
                        </StyledAccesibilityUspDisplay>
                    </StyledWrapper>
                ) : (
                    uspItems.map((usp) => (
                        <UspItem key={weakKey(usp)} uspItem={usp} shade={shade} variant="bodySm" />
                    ))
                )}
            </StyledUspContainer>
        );
    }
);
