import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { ifProp, switchProp } from 'styled-tools';
import { center, visuallyHidden } from '~/shared/utils/styled';
import { breakpoints } from '~/theme';
import { StyledInvalidMessage } from '../FormElements';

export const StyledContent = styled(m.div, {
    shouldForwardProp: (prop: string) => prop !== 'sizingText',
})<{ sizingText?: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spaces[2],
    }),
    ifProp('sizingText', ({ theme }) => ({
        border: `1px solid ${theme.colors.light40}`,
        borderRadius: '5px',
    }))
);

export const StyledSizeList = styled.ul<{ sizingText?: boolean }>(({ sizingText }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: !sizingText ? '0' : '10px',
}));

export const StyledItem = styled.li(() => ({
    margin: '-1px 0 0 -1px',
}));

export const StyledChip = styled.label<{ variant?: 'more' }>(
    ({ theme }) => ({
        ...center,
        minWidth: 59,
        minHeight: 40,
        boxShadow: `inset 0px 0px 0px 1px ${theme.colors.light50}`,
        backgroundColor: theme.colors.light,
        cursor: 'pointer',
        fontSize: theme.fontSizes.sm,

        ['&:hover']: {
            backgroundColor: theme.colors.light30,
        },
    }),
    ifProp('active', {
        border: '2px solid',
    }),
    switchProp('variant', {
        more: ({ theme }) => ({
            border: 'none',
            margin: 0,
            boxShadow: `inset 0px 0px 0px 2px ${theme.colors.dark}`,
            backgroundColor: theme.colors.light30,
        }),
    })
);

export const StyledInput = styled.input(({ theme }) => ({
    ...visuallyHidden,
    [`&:checked + ${StyledChip}`]: {
        backgroundColor: theme.colors.dark,
        color: theme.colors.light,
    },
    [`&:disabled + ${StyledChip}`]: {
        color: theme.colors.light50,
        backgroundColor: theme.colors.light30,
        cursor: 'default',
    },
    [`&:focus + ${StyledChip}`]: {
        boxShadow: `inset 0px 0px 0px 3px ${theme.focus.outlineColor}`,
    },
}));

export const StyledSizeSelectorInvalidMessage = styled(StyledInvalidMessage)(({ theme }) => ({
    height: theme.spaces[2],

    [breakpoints.sm]: {
        height: theme.spaces[3],
    },
}));

export const StyledSizeTextWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[2],
    padding: '10px',
    backgroundColor: theme.colors.yellowXLight,
    borderBottom: `${theme.spaces[1]} solid ${theme.colors.yellowLight}`,
}));
