import { useAlgoliaConnector } from '~/shared/utils';
import { useAlgoliaConfig } from '../useAlgoliaConfig';
import { useQuery } from 'react-query';
import { AlgoliaHit } from '~/templates/blocks/components/Carousel/models';
import { SearchResponse } from '@algolia/client-search';

export const useAlgoliaContextId = (contextId = '') => {
    const { defaultIndex } = useAlgoliaConfig();
    const searchClient = useAlgoliaConnector();

    const { data: result } = useQuery(
        ['algoliaContextId', contextId],
        async () =>
            searchClient.search<AlgoliaHit>([
                { indexName: defaultIndex, params: { ruleContexts: [contextId] } },
            ]),
        { enabled: !!contextId }
    );

    return (result?.results[0] as SearchResponse<AlgoliaHit>)?.hits;
};
