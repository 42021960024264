import { useQuery } from 'react-query';
import { getProduct } from '~/services/commerce';
import { useFranchise } from '~/shared/utils';

type Config = {
    productId: string;
    productColorId: string;
};

export const useProduct = (config?: Config | null) => {
    const { franchiseAPIPath } = useFranchise();

    const { data: product, isLoading } = useQuery(
        ['product', config?.productId, config?.productColorId],
        async () => {
            if (!config) {
                return;
            }
            const response = await getProduct({
                franchiseAPIPath,
                productColorId: config?.productColorId,
                productId: config?.productId,
            });
            return response;
        },
        {
            enabled: !!config?.productId && !!config?.productColorId,
        }
    );

    return { product, isLoading };
};
