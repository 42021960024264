export const defaultConfig = {
    momentumMinSpeed: 0.25,
    momentumDeclineRate: 1.12,
    momentumDataSize: 4,

    boundaryResistance: 0.5,
    boundarySnapBackDuration: 150,

    autoPlayScrollSpeed: 17,
    autoPlayScrollLenght: 1,

    arrowScrollLength: 0.8,
    scrollbar: true,
};

export type AdjustableConfig = {
    momentumMinSpeed?: number;
    momentumDeclineRate?: number;

    boundaryResistance?: number;
    boundarySnapBackDuration?: number;

    arrowScrollLength?: number;
    scrollbar?: boolean;
};
