import styled from '@emotion/styled';
import { m } from 'framer-motion';

export const StyledUspContainer = styled.div<{ centerAligned?: boolean }>(({ centerAligned }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '1em',

    a: {
        color: 'inherit',

        '&:hover': {
            color: 'inherit',
            textDecoration: 'none',
        },
    },

    justifyContent: centerAligned ? 'center' : 'unset',
}));

export const StyledWrapper = styled.div(() => ({
    position: 'relative',
}));

export const StyledAccesibilityUspDisplay = styled.ul(({ theme }) => ({
    background: theme.colors.dark,
    width: 'clamp(200px, 50vw, 480px)',
    padding: theme.spaces[2],
    borderRadius: 3,
    listStyle: 'none',
}));

export const StyledUspItem = styled(m.div)(() => ({
    display: 'flex',
    justifyContent: 'center',
}));
