import create from 'zustand';

type UseIsInitialRenderState = {
    isInitialRender: boolean;
    setIsInitialRender: (val: boolean) => void;
};

export const useIsInitialRenderState = create<UseIsInitialRenderState>((set) => ({
    isInitialRender: true,
    setIsInitialRender: (val: boolean): void => {
        set(() => ({ isInitialRender: val }));
    },
}));
