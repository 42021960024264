import { memo } from 'react';
import { useMarkUserBasket, usePageViewTracker } from './hooks';

/**
 * Dynamic tracking component, which can be used to fire events
 * based on initial or changing data
 */
export const DynamicTracking = memo(() => {
    useMarkUserBasket();
    usePageViewTracker();

    return <></>;
});
