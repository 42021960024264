import { useSession } from 'next-auth/react';
import { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useBasket } from '~/features/basket/hooks/useBasket';
import { useBasketPaths } from '~/features/basket/hooks/useBasketPaths';
import { qualifyAbandonedBasket } from '~/features/basket/service';
import { useMarkUserBasketState } from './useMarkUserBasketState';

const noop = () => undefined;

/**
 * Watches for a user to be logged in and marks the basket with the user
 * this will have multiple effects, such as qualifying the basket for
 * abandoned basket emails. And marking products as member offers.
 * @returns
 */
export const useMarkUserBasket = () => {
    const { markBasketWithUserUrl } = useBasketPaths();
    const { data: session } = useSession();
    const { data: basket, refetch } = useBasket();
    const tracked = useRef(false);
    const { qualifiedUrl, setQualifiedUrl } = useMarkUserBasketState();
    const customerNumber = session?.user.customerNumber;
    const basketId = basket?.id;

    const { isLoading, mutateAsync } = useMutation<void, Response, string>(
        (verificationUrl) => qualifyAbandonedBasket(verificationUrl),
        {
            // no-op we don't want to inform the user of errors
            onError: noop,
            // Refetch the basket after a successful qualification
            onSuccess: () => refetch(),
        }
    );

    // this function compares the current url with the qualified url
    // and triggers the qualification if they differ
    const qualifyUserForAbandonedBasket = useCallback(() => {
        if (isLoading) {
            return;
        }

        const verificationUrl = markBasketWithUserUrl(customerNumber);

        if (verificationUrl !== qualifiedUrl) {
            setQualifiedUrl(verificationUrl);
            mutateAsync(verificationUrl);
        }
    }, [
        customerNumber,
        isLoading,
        markBasketWithUserUrl,
        mutateAsync,
        qualifiedUrl,
        setQualifiedUrl,
    ]);

    // Perform the qualification when the user is logged in
    useEffect(() => {
        if (customerNumber && basketId && !tracked.current) {
            tracked.current = true;
            qualifyUserForAbandonedBasket();
        }
    }, [customerNumber, basketId, qualifyUserForAbandonedBasket]);
};
