import Head from 'next/head';
import React from 'react';

/**
 * Adds all favicons to the head
 *
 * @NOTE: This file is automatically generated from `/helpers/generate-favicon/createFaviconComponent.ts`
 */
export const ToejekspertenFavicon = () => (
    <Head>
        <meta name="theme-color" content="#fff" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="application-name" content="Tøjeksperten" />
        <meta name="apple-mobile-web-app-title" content="TE" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <link rel="manifest" href="/assets/toejeksperten-favicon/manifest.webmanifest" />
        <link rel="icon" type="image/x-icon" href="/assets/toejeksperten-favicon/favicon.ico" />
        <link
            rel="icon"
            type="image/png"
            sizes="48x48"
            href="/assets/toejeksperten-favicon/favicon-48x48.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/assets/toejeksperten-favicon/favicon-32x32.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/assets/toejeksperten-favicon/favicon-16x16.png"
        />
        <link
            rel="apple-touch-startup-image"
            media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            href="/assets/toejeksperten-favicon/apple-touch-startup-image-1284x2778.png"
        />
        <link
            rel="apple-touch-startup-image"
            media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            href="/assets/toejeksperten-favicon/apple-touch-startup-image-2778x1284.png"
        />
        <link
            rel="apple-touch-startup-image"
            media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            href="/assets/toejeksperten-favicon/apple-touch-startup-image-1170x2532.png"
        />
        <link
            rel="apple-touch-startup-image"
            media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            href="/assets/toejeksperten-favicon/apple-touch-startup-image-2532x1170.png"
        />
        <link
            rel="apple-touch-startup-image"
            media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            href="/assets/toejeksperten-favicon/apple-touch-startup-image-640x1136.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="/assets/toejeksperten-favicon/apple-touch-icon-76x76.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="/assets/toejeksperten-favicon/apple-touch-icon-72x72.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="/assets/toejeksperten-favicon/apple-touch-icon-60x60.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="57x57"
            href="/assets/toejeksperten-favicon/apple-touch-icon-57x57.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/assets/toejeksperten-favicon/apple-touch-icon-180x180.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="167x167"
            href="/assets/toejeksperten-favicon/apple-touch-icon-167x167.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/assets/toejeksperten-favicon/apple-touch-icon-152x152.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="/assets/toejeksperten-favicon/apple-touch-icon-144x144.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/assets/toejeksperten-favicon/apple-touch-icon-120x120.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="/assets/toejeksperten-favicon/apple-touch-icon-114x114.png"
        />
    </Head>
);
