import Script from 'next/script';
import React, { memo } from 'react';
import { useFrame } from '~/shared/utils';
import { weakKey } from '~/shared/utils/jsx';

export const Scripts = memo(() => {
    const { data: frame } = useFrame();
    const { scripts = [], snippets = [] } = frame?.settings?.customScripts || {};

    return (
        <>
            {snippets.map((snippet, index) => (
                <Script key={weakKey({ snippet })} id={`snippet-${index}`}>
                    {snippet}
                </Script>
            ))}
            {scripts.map((scriptProps, index) => (
                <Script key={weakKey(scriptProps)} id={`script-${index}`} {...scriptProps} />
            ))}
        </>
    );
});
