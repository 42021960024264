import styled from '@emotion/styled';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { ifProp } from 'styled-tools';

export const StyledScrollArea = styled(ScrollArea.Root)(() => ({
    overflow: 'hidden',
    cursor: 'grab',

    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
        display: 'none',
    },

    '&:active': {
        cursor: 'grabbing',
    },
}));

export const StyledItemWrapper = styled(ScrollArea.Viewport, {
    shouldForwardProp: (prop: string) => prop !== 'hasGutter',
})<{ hasGutter: boolean }>(
    {
        overflowX: 'scroll',
    },
    ifProp('hasGutter', ({ theme }) => ({
        paddingLeft: theme.gridConfig.gutter,
        paddingRight: theme.gridConfig.gutter,
    }))
);
