import * as ScrollArea from '@radix-ui/react-scroll-area';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const StyledScrollArea = styled(ScrollArea.Root)({
    overflow: 'hidden',
});

export const StyledViewport = styled(ScrollArea.Viewport)({
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
});

export const StyledThumb = styled(ScrollArea.Thumb)(({ theme }) => ({
    flex: 1,
    background: theme.colors.dark,
    borderRadius: 3,
    // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
    position: 'relative',
    cursor: 'grab',
    '&:active': {
        cursor: 'grabbing',
    },
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        minWidth: 44,
        minHeight: 44,
    },
}));

export const StyledScrollbar = styled(ScrollArea.Scrollbar)(({ theme }) => ({
    display: 'flex',
    margin: 0,
    backgroundColor: theme.colors.light50,
    // ensures no selection
    userSelect: 'none',
    // disable browser handling of all panning and zooming gestures on touch devices
    touchAction: 'none',
    transition: 'background 160ms ease-out',
    '&[data-orientation="vertical"]': { width: 3 },
    '&[data-orientation="horizontal"]': {
        flexDirection: 'column',
        height: 1,
    },
}));

export const StyledScrollBarContainer = styled.div<{ hasGutter: boolean; showScrollbar: boolean }>(
    ({ showScrollbar }) => ({
        position: 'relative',
        padding: '15px 0',
        zIndex: 1,
        visibility: showScrollbar ? 'visible' : 'hidden',
    }),
    ifProp('hasGutter', ({ theme }) => ({
        left: theme.gridConfig.gutter,
        right: theme.gridConfig.gutter,
        width: `calc(100% - ${theme.gridConfig.gutter} - ${theme.gridConfig.gutter})`,
    }))
);
