import React, { memo, useMemo } from 'react';
import { FRANCHISE } from '~/constants/franchise';
import { useFranchise } from '~/shared/utils';
import {
    JunkDeLuxeFavicon,
    LindberghFavicon,
    ToejekspertenFavicon,
    WagnerFavicon,
    BisonFavicon,
} from './components';

export const DynamicFavicon = memo(() => {
    const { key } = useFranchise();

    return useMemo(
        () =>
            ({
                [FRANCHISE.WAGNER]: <WagnerFavicon />,
                [FRANCHISE.WAGNER_SE]: <WagnerFavicon />,
                [FRANCHISE.TOEJEKSPERTEN]: <ToejekspertenFavicon />,
                [FRANCHISE.LINDBERGH]: <LindberghFavicon />,
                [FRANCHISE.JUNK_DE_LUXE]: <JunkDeLuxeFavicon />,
                [FRANCHISE.BISON]: <BisonFavicon />,
            }[key]),
        [key]
    );
});
