import React, { useMemo, useState } from 'react';
import { ProductViewModel } from '~/lib/data-contract';
import { shakeAnimation, Text } from '~/shared/components';
import { useTranslation } from '~/shared/utils/translation';
import { bezierValues } from '~/theme/themes/animations/baseAnimations';
import {
    StyledChip,
    StyledContent,
    StyledInput,
    StyledItem,
    StyledSizeList,
    StyledSizeSelectorInvalidMessage,
    StyledSizeTextWrapper,
} from './styled';

export type SizeSelectorProps = Pick<ProductViewModel, 'sizes'> & {
    onSelect: (variantId: string) => void;
    name: string;
    activeSizeId?: string | null;
    onAnimationEnd?: () => void;
    isAnimating?: boolean;
    showErrorMessage?: boolean;
    stockFrom?: 'web' | 'store';
    sizingText?: string;
};

const MAX_ITEMS = 13;

export const SizeSelector = ({
    sizes,
    name,
    activeSizeId,
    onSelect,
    isAnimating,
    onAnimationEnd,
    showErrorMessage,
    stockFrom = 'web',
    sizingText,
}: SizeSelectorProps) => {
    const [max, setMax] = useState(MAX_ITEMS);
    const { translate } = useTranslation();
    const remaining = useMemo(() => (sizes || []).length - max, [sizes, max]);

    const onShakeAnimationComplete = () => {
        onAnimationEnd?.();
    };

    return (
        <StyledContent
            initial={'inactive'}
            animate={isAnimating ? 'shake' : ''}
            variants={{
                shake: {
                    ...shakeAnimation,
                    transition: {
                        ease: bezierValues.entrance.expressive,
                        duration: 0.5,
                    },
                },
            }}
            onAnimationComplete={onShakeAnimationComplete}
            sizingText={!sizingText ? false : true}
        >
            {!sizingText ? (
                <Text as="div" variant="overline">
                    {translate('productDetails.size')}
                </Text>
            ) : (
                <StyledSizeTextWrapper>
                    <Text as="div" variant="overline" style={{ fontWeight: 600 }}>
                        {translate('productDetails.size')}
                    </Text>
                    {sizingText && (
                        <Text as="p" variant={'caption'} style={{ fontWeight: 500 }}>
                            {sizingText}
                        </Text>
                    )}
                </StyledSizeTextWrapper>
            )}
            <StyledSizeList sizingText={!sizingText ? false : true}>
                {sizes?.slice(0, max).map(
                    ({ size, variantId, inStockOnWeb, inStockInStore }, index) =>
                        variantId && (
                            <StyledItem key={index}>
                                <StyledInput
                                    name={name}
                                    id={`${name}-${size}` || ''}
                                    type="radio"
                                    onChange={() => onSelect(variantId)}
                                    checked={variantId === activeSizeId}
                                    title={size || ''}
                                    disabled={stockFrom === 'web' ? !inStockOnWeb : !inStockInStore}
                                    autoComplete="off"
                                />
                                <StyledChip children={size} htmlFor={`${name}-${size}` || ''} />
                            </StyledItem>
                        )
                )}
                {remaining > 0 && (
                    <StyledItem>
                        <StyledChip
                            as="button"
                            onClick={() => setMax(Infinity)}
                            children={`+${remaining}`}
                            variant="more"
                        />
                    </StyledItem>
                )}
            </StyledSizeList>
            {showErrorMessage && (
                <StyledSizeSelectorInvalidMessage
                    children={translate('productDetails.selectSize')}
                />
            )}
        </StyledContent>
    );
};
