import create from 'zustand';

type State = {
    qualifiedUrl: string | null;
    setQualifiedUrl: (qualifiedUrl: string) => void;
};

export const useMarkUserBasketState = create<State>((set) => ({
    qualifiedUrl: null,
    setQualifiedUrl: (qualifiedUrl) => {
        set(() => ({
            qualifiedUrl,
        }));
    },
}));
