import styled from '@emotion/styled';
import { Button } from '../Button';
import { scrollbarThin } from '~/shared/utils/styled';

export const StyledMiniCarouselList = styled.ul({
    display: 'flex',
});

export const StyledMiniCarouselListItem = styled.li();

export const StyledContainer = styled.div({
    position: 'relative',
});

export const StyledSizeOverlay = styled.div(({ theme }) => ({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.colors.light,
    padding: theme.spaces[2],
    zIndex: 2,
    flexDirection: 'column',
    border: `1px solid ${theme.colors.light50}`,
}));

export const StyledCloseButton = styled.div(({ theme }) => ({
    position: 'absolute',
    top: theme.spaces[2],
    right: theme.spaces[2],
}));

export const StyledSelectorWrapper = styled.div(({ theme }) => ({
    overflow: 'auto',
    padding: theme.spaces[3],
    ...scrollbarThin,
}));

export const StyledSpinnerOverlay = styled.div(({ theme }) => ({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.colors.transparentLight,
    padding: theme.spaces[2],
    zIndex: 2,
}));

export const StyledNavigationButton = styled(Button)(({ theme }) => ({
    ['&:hover svg']: {
        color: theme.colors.dark70,
    },
}));

export const StyledHeader = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});
