import { useCallback, useMemo } from 'react';
import { mapSource } from './utils/sourceMapper';

import { usePage } from '~/templates/pages';
import { usePrevious } from 'react-use';

/**
 * Exposes current page type in a presentable name
 * can be used for tracking scenarios
 */
export const useSource = () => {
    const { type } = usePage();

    const prettyFormatType = useCallback((type: string) => {
        return mapSource(type);
    }, []);

    const source = useMemo(() => {
        if (!type) {
            return 'Other';
        }

        return prettyFormatType(type) || 'Other';
    }, [type, prettyFormatType]);

    const previousSource = usePrevious<string>(source);

    return { source, previousSource, prettyFormatType };
};
