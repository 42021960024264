import React, { memo } from 'react';
import { useTranslation } from '~/shared/utils';
import { StyledSkipToContent } from './styled';

export const SkipToContent = memo(() => {
    const { translate } = useTranslation();

    return (
        <StyledSkipToContent id="skip" href="#content">
            {translate('global.skipToContent')}
        </StyledSkipToContent>
    );
});
