import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useSessionStorage } from 'react-use';
import { isSSR } from '~/shared/utils';
import { useIsInitialRenderState } from './useIsInitialRenderState';

export const initialLocationSessionKey = 'initialLocation';

/**
 * Sets 'isInitialRender' state and 'initialLocation' session token
 */
export const useIsInitialRender = () => {
    const router = useRouter();
    const [initialLocation, setInitialLocation] = useSessionStorage<string | undefined>(
        initialLocationSessionKey
    );
    const { isInitialRender, setIsInitialRender } = useIsInitialRenderState();

    const onRouteChangeDone = useCallback(() => {
        if (isInitialRender && !isSSR) {
            setIsInitialRender(false);
        }
    }, [isInitialRender, setIsInitialRender]);

    useEffect(() => {
        if (!initialLocation && !isSSR) {
            setInitialLocation(window.location.href);
        }
    }, [initialLocation, setInitialLocation]);

    useEffect(() => {
        router.events.on('routeChangeComplete', onRouteChangeDone);

        return () => {
            router.events.off('routeChangeComplete', onRouteChangeDone);
        };
    }, [onRouteChangeDone, router.events]);

    return { initialLocation, isInitialRender };
};
