import create from 'zustand';

type TopbarCampaignState = {
    hideTopBarCampaign: boolean;
    setHideTopBarCampaign: (state: boolean) => void;
};

export const useTopbarCampaignState = create<TopbarCampaignState>((set) => ({
    hideTopBarCampaign: false,
    setHideTopBarCampaign: (state) => set(() => ({ hideTopBarCampaign: state })),
}));
