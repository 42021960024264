import { formatIndex, useFrame, useFranchise } from '~/shared/utils';

export const useAlgoliaConfig = () => {
    const { activeMarket } = useFranchise();
    const { data: frame } = useFrame();
    const token = frame?.search?.algolia?.apiToken || '';
    const key = frame?.search?.algolia?.appKey || '';

    const defaultIndex = formatIndex(activeMarket?.algoliaIndex || '');

    const orderIndexes = [
        {
            key: defaultIndex,
            value: 'commerce.sort.mostRelevant',
        },
        {
            key: `${activeMarket?.algoliaIndex}_price_asc`,
            value: 'commerce.sort.price_asc',
        },
        {
            key: `${activeMarket?.algoliaIndex}_price_desc`,
            value: 'commerce.sort.price_desc',
        },
        {
            key: `${activeMarket?.algoliaIndex}_newest`,
            value: 'commerce.sort.newest',
        },
    ];

    return { orderIndexes, defaultIndex, token, key };
};
