import React, { memo } from 'react';
import Head from 'next/head';
import { OpenGraph as OpenGraphType, Pages } from '~/lib/data-contract';
import { useFrame, useFranchise } from '~/shared/utils';
import { usePage } from '~/templates/pages';
import { useProduct } from '~/shared/utils/product';

export const OpenGraph = memo(() => {
    const { ogTitle, ogDescription, ogImage, meta, url: pageUrl } = usePage<
        Pages & OpenGraphType
    >();
    const { data: product } = useProduct();
    const { activeMarket } = useFranchise();
    const { data: frame } = useFrame();

    const title = ogTitle || meta?.metaTitle;
    const description = ogDescription || meta?.metaDescription;
    const image = ogImage?.src || frame?.settings?.trackingSetup?.socialImage?.src;
    const name = frame?.settings?.trackingSetup?.siteName;
    const url = product?.canonical || pageUrl;

    return (
        <Head>
            {title && <meta property="og:title" content={title} />}

            {description && <meta property="og:description" content={description} />}

            <meta property="og:type" content="website" />

            {url ? <meta property="og:url" content={url} /> : null}

            {image && !product && <meta property="og:image" content={image} />}

            {activeMarket?.locale && <meta property="og:locale" content={activeMarket.locale} />}

            {name && <meta property="og:site_name" content={name} />}
        </Head>
    );
});
